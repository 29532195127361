import { localizedProductPagePathRoot } from '@otovo/shared/components/Navigation/constants';
import throwForFalsy from '@otovo/shared/lib/throwForFalsy';
import { Otovo$Locale } from '@otovo/shared/types/otovoweb';
import { Sanity$DocumentType } from '@otovo/shared/types/sanityTypes';

export const getLocalizedPathRoot = (
  sanityDocumentType: Sanity$DocumentType,
  locale: Otovo$Locale,
): string => {
  return throwForFalsy(
    {
      productPage: localizedProductPagePathRoot,
    }[sanityDocumentType][locale],
  );
};
